import * as React from "react"
import {useEffect, useRef} from "react"
import Layout from "../layout/layout"
import PageTile from "../layout/pageTile"
import "../styles/stylesloader.scss"
import { StaticImage } from "gatsby-plugin-image"
import LanguageData from "../languageFiles/DE.json";
import Seo from "../seo";


const IndexPage = () => {

  const maps = useRef();
  const mapstimer = useRef();

  useEffect(() => {
    //window.addEventListener('scroll', handleScroll);
    return () => {
      //window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (data) => {
    if (maps.current) {
    
    if (!mapstimer.current) {
      maps.current.style.pointerEvents = "none";
      mapstimer.current = setTimeout(() => {
        maps.current.style.pointerEvents = "all";
        mapstimer.current = undefined;
      },2000)
    }
  }
    console.log(data);
  };

  return (
    
    <Layout>
      <Seo />
      <PageTile innerClass="about main-grid-columns" background="lightPurple">
        <h1 className="section-title">{LanguageData.about.title}</h1>
        <h2 className="section-subtitle">{LanguageData.about.subtitle}</h2>
        <p className="textblock-x-4">{LanguageData.about.text}</p>
        <StaticImage src="../images/HebammeAnnaPortrait_neu.jpg" alt="Portrait der Hebamme Annkathrin Rinke in der Ulmer Altstadt" className="article-img-big"/>
        <p className="img-description">{LanguageData.about.imgDescription}</p>
      </PageTile>

      <PageTile innerClass="news main-grid-columns">
        <h1 className="section-title">{LanguageData.news.title}</h1>
        {LanguageData.news.articles.map((a,key) => (<div className={`news-article ${key%2?"darkRose end":"rose"}`} key={key}><h5 className="news-header">{a.title}</h5><p dangerouslySetInnerHTML={{__html: a.text}}></p></div>))}        
      </PageTile>

      <PageTile innerClass="services main-grid-columns" background="lightPurple">
        <h1 className="section-title">{LanguageData.services.title}</h1>
        <div className="service-section main-grid-columns">
          <h2 className="section-subtitle">{LanguageData.services.sections[0].title}</h2>
          <div className="textblock textblock-x-4">
            <p>{LanguageData.services.sections[0].text}</p>
            <ul>{LanguageData.services.sections[0].tags.map((t,key) => (<li key={key}>{t}</li>))}</ul>
          </div>
          <StaticImage className="article-img" src="../images/schwangere-vor-heuballen.jpg" alt="Schwangere Frau vor Heuballen in der Nähe von Ulm"/>
        </div>
        <div className="service-section main-grid-columns">
          <h2 className="section-subtitle">{LanguageData.services.sections[1].title}</h2>
          <StaticImage className="article-img" src="../images/mutter-mit-neugeborenem.jpg" alt="Mutter mit Neugeborenem Baby"/>
          <div className="textblock textblock-x-4">
            <p>{LanguageData.services.sections[1].text}</p>
            <ul>{LanguageData.services.sections[1].tags.map((t,key) => (<li key={key}>{t}</li>))}</ul>
          </div>
          
        </div>      
      </PageTile>

      <PageTile innerClass="consultation main-grid-columns">
        <h1 className="section-title">{LanguageData.consultationVorsorge.title}</h1>
        <ul>
          {LanguageData.consultationVorsorge.services.map((c,key) => (<li key={key}>{c}</li>))}
        </ul>
      </PageTile>

      <PageTile innerClass="consultation main-grid-columns">
        <h1 className="section-title">{LanguageData.consultationWochenbett.title}</h1>
        <ul>
          {LanguageData.consultationWochenbett.services.map((c,key) => (<li key={key}>{c}</li>))}
        </ul>
      </PageTile>

      <PageTile innerClass="consultation main-grid-columns" background="lightPurple">
        <h1 className="section-title">{LanguageData.consultation.title}</h1>
        <ul>
          {LanguageData.consultation.services.map((c,key) => (<li key={key}>{c}</li>))}
        </ul>
      </PageTile>

      <PageTile outerClass="location-tile" innerClass="location main-grid-columns">
        <h1 className="section-title">Einzugsgebiet</h1>
        <h2 className="section-subtitle">30km</h2>
        <p className="textblock-x-4">Mein Betreuungsradius für Hausgeburten liegt ca. 30 km um die Ulmer Weststadt, heißt, ich möchte gerne innerhalb von 30-40 min bei dir sein. Solltest du weiter weg wohnen und dir eine außerklinische Geburt wünschen, kannst du gerne bei mir im Geburtszimmer im Vulvarium gebären. Wenn du dir unsicher bist, sprich am besten mit mir.</p>
      </PageTile>
    </Layout>
    
  )
}

export default IndexPage
